.footer {
  background-color: var(--bg-1);
}
.footer img {
  max-width: 200px;
  margin-bottom: 1rem;
}
.footer_bottom {
  padding: 10px 0;
  background: rgb(0 0 0 / 10%);
}
.footer_bottom p {
  margin-bottom: 0;
}
.links {
  list-style-type: none;
}
.links > li {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: 0.2s ease;
}
.links > li:hover {
  color: var(--accent-color);
}
.links > li > svg {
  font-size: 0.8rem;
}
.header {
  font-weight: 600;
  margin-bottom: 1rem;
  position: relative;
  display: inline-block;
}
.header:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 80%;
  height: 1px;
  background-color: var(--text-color);
}
.logo {
  align-self: center;
}
.footer_bottom,
.footer_bottom p {
  font-size: 0.9rem;
}
.footer_bottom > div > div {
  gap: 1rem;
}
