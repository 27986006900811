.work {
  margin: 0.25rem 0.3rem;
  padding: 2rem;
  background: var(--bg-1);
  border-radius: 0.5rem;
  height: calc(100% - 0.5rem);
  overflow: hidden;
  position: relative;
}
.work:before,
.wrapper:before {
  content: "";
  position: absolute;
  width: 224px;
  height: 224px;
  filter: contrast(0) opacity(0.2);
  right: -100px;
  transition: 0.6s ease;
  bottom: -100px;
  background-image: url("../../../assets/ebisu-icon.svg");
}
.work:hover:before,
.wrapper:hover:before {
  transform: scale(1.1);
}
.work h4,
.content h4 {
  font-size: clamp(1rem, 2vw, 1.4rem);
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.work div,
.content div {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 1rem;
}

.work div span,
.content div span {
  padding: 5px 10px;
  font-size: 0.8rem;
  background: var(--accent-3);
  border-radius: 25px;
}
.work div span:first-child,
.content div span:first-child {
  background-color: var(--accent-color);
  color: #fff;
}

.wrapper {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  padding: 1.5rem;
  border-radius: 10px;
  border: 1px solid #f0f0f0;
  background-color: var(--bg-1);
  height: 100%;
}
/* .content,
.images {
  flex: 1 250px;
} */
.content {
  display: flex;
  flex-direction: column;
  align-items: center; /* ボタンを中央揃え */
  justify-content: center; /* 高さ方向のバランスを取る */
}

.images img {
  display: flex;
  border-radius: 8px;
  z-index: 2;
  position: relative;
}
.featuredWork {
  flex: 33.33%;
  max-width: 33.33%;
  padding: 5px;
}
.featuredWork.hasImage {
  flex: 1 calc(100% - 33.33%);
  max-width: unset;
}
/* .featuredWork:nth-child(1n + 2) {
  flex: 66.67%;
  max-width: 66.67%;
} */
.logo {
  max-height: 100px;
  margin-bottom: 1rem;
  mix-blend-mode: multiply;
}
@media (max-width: 1005px) and (min-width: 670px) {
  .workWrapper {
    max-width: 50%;
    flex: 50%;
  }
}
@media (max-width: 768px) {
  .featuredWork {
    flex: 1 300px;
    max-width: unset;
  }
  .featuredWork.hasImage {
    flex: 100%;
  }
}
