.section {
  background-color: #fafafa;
  padding: 100px 0 50px;
}
.card_wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px 10px;
}
.card span,
.card h4,
.card > p,
.card > p > p > ul {
  z-index: 1;
  position: relative;
  transition: 0.5s ease-in-out;
}
.card ul {
  list-style: none;
}
.card {
  border-radius: 5px;
  padding: calc(4rem + 30px) 2rem 4rem;
  /* background: linear-gradient(0deg, #f1f0ff, #fff0f5); */
  position: relative;
  overflow: hidden;
  flex: 1 1;
  transition: 0.3s ease;
  border: 1px solid transparent;
  z-index: 2;
}
/* .card_wrapper:first-child .card {
  background: #ffeff0;
}
.card_wrapper:nth-child(2) .card {
  background: #fff6e8;
}
.card_wrapper:nth-child(3) .card {
  background: #fff8d6;
}
.card_wrapper:nth-child(4) .card {
  background: #ebffbf;
}
.card_wrapper:nth-child(5) .card {
  background: #e1f3ff;
} */
.images {
  width: 110px;
  height: 110px;
  margin-bottom: 3rem;
  border-radius: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.images::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: var(--accent-5);
  border-radius: 100px;
  transition: 0.5s ease-in-out;
  z-index: 0;
}
.card span {
  font-size: 1.2rem;
  display: block;
  position: relative;
  font-weight: 300;
  margin-bottom: 1rem;
}
.card span:before {
  content: "";
  position: absolute;
  width: calc(100% - 45px);
  height: 1px;
  background: var(--text-color);
  top: calc(50% - 1px);
  right: 0;
  transition: 0.5s ease-in-out;
}
.card:hover {
  /* transform: scale(1.05); */
  /* border-color: rgb(0 0 0 / 10%); */
}
/* .card:hover .images::before {
  transform: scale(10.1);
  transform-origin: center;
} */
/* .card:hover span,
.card:hover h4,
.card:hover p,
.card:hover li {
  color: #fff;
} */
/* .card:hover span:before {
  background-color: #fff;
}  */
