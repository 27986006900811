.section {
  /* background-color: #010101; */
  background: linear-gradient(270deg, #80a5ef, #2e5abb);
  color: var(--bg);
  padding: 50px 0;
}
.section img {
  /* padding: 0 2rem; */
}
.section * {
  z-index: 2;
}
.section h1 {
  font-size: clamp(1.5rem, 3vw, 3.5rem);
  font-weight: 800;
  text-transform: uppercase;
}
.section h3 {
  font-size: clamp(1.5rem, 3vw, 3.5rem);
  font-weight: 500;
  margin-bottom: 1rem;
}

.section p {
  margin-bottom: 2rem;
  font-size: clamp(0.9rem, 1vw, 1.3rem);
}
@media (max-width: 768px) {
  .section {
    min-height: auto;
  }
  .section img {
    padding: 0 2rem;
  }
}
