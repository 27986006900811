.job {
  width: 100%;
  padding: 2rem 4rem;
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  border-radius: 10px;
  margin-bottom: 1rem;
}
.job h4 {
  font-weight: 400;
  margin-bottom: 1rem;
}
.job h4 span {
  font-weight: 600;
}
.job ul {
  padding-left: 1rem;
  margin-bottom: 1rem;
}
.job a {
  color: var(--accent-color);
  font-weight: 600;
}

@media (max-width: 768px) {
  .job {
    padding: 2rem 1rem;
    font-size: 0.9rem;
  }
}
