.sec {
  background-color: var(--bg-1);
  background-image: url("../../../assets/map.png");
  background-position: center center;
  background-size: cover;
  padding: 4rem 2rem;
  border-radius: 1rem;
}
.kokoro {
  padding: 1rem;
  border-radius: 0.7rem;
  background-color: rgb(255 255 255 / 40%);
  border: 1px solid rgb(255 255 255 / 40%);
  backdrop-filter: blur(5px);
}
.image {
  width: 150px;
  display: block;
  margin-bottom: 1rem;
}
@media (max-width: 768px) {
  .sec {
    padding: 4rem 1rem;
    background-position: right;
  }
}
