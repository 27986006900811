.swiper {
  margin-right: -400px !important;
  margin-left: 2rem !important;
}
.section {
  background-color: var(--accent-5);
  color: #fff;
}
.work_wrapper {
  padding: 4rem 2rem;
  background: rgb(0 0 0 / 20%);
  border-radius: 1rem;
  height: 100%;
}
.work_wrapper img {
  height: 150px;
  width: auto;
  margin-bottom: 1rem;
}
.work_wrapper h3 {
  margin-bottom: 1rem;
}
.slide {
  height: auto !important;
}
@media (max-width: 768px) {
  .swiper {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .work_wrapper {
    padding: 2rem 1rem;
  }
  .work_wrapper p {
    font-size: 0.9rem;
  }
  .content {
    margin-bottom: 2rem;
  }
}
