.message {
  background-color: var(--bg-1);
  padding: 4rem 4rem 2rem;
  border-radius: 1rem;
}
.message p {
  font-size: clamp(0.9rem, 1.2vw, 1.1rem);
}
.quote svg {
  font-size: 4rem;
  color: #ccc;
  margin-bottom: 1rem;
}
.message h3 {
  font-size: 1.4rem;
  font-weight: normal;
}
.image {
  margin-left: 2rem;
  display: flex;
  z-index: 2;
  position: relative;
}
.credentials {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.credentials img {
  max-width: 70px;
}
.credentials span {
  display: block;
}
.credentials span:last-child {
  font-size: 1.5rem;
  font-weight: 500;
}
.credentials span:last-child {
  font-size: 1.3rem;
  /* color: var(--accent-color); */
}
@media (max-width: 768px) {
  .image {
    margin: 0 auto -20px;
    max-width: 90%;
  }
  .message {
    padding: 2rem 0.5rem 2rem;
  }
}
