.wrapper {
  position: relative;
}
.wrapper:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 70%);
  z-index: 1;
}
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hero {
  position: relative;
  z-index: 2;
  padding: 200px 0;
  /* height: 90vh; */
  display: flex;
  align-items: center;
  color: #fff;
}
.hero > div {
  width: 100%;
}
.hero h1 {
  font-size: clamp(1.7rem, 3vw, 3rem);
  font-weight: 500;
  margin-bottom: 1rem;
}
.hero h1 span {
  color: var(--accent-3);
}
.hero h3 {
  font-size: clamp(0.9rem, 2vw, 1.3rem);
  font-weight: 500;
}
.button {
  background-color: var(--accent-2);
  padding: 16px 30px;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  gap: 10px;
  border: none;
  border-radius: 25px;
  color: #fff;
  cursor: pointer;
  transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.button svg {
  position: relative;
  left: 0px;
  transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
/* .button:hover {
  background-color: var(--accent-3);
  color: var(--text-color);
} */
.button:hover svg {
  left: 10px;
}
