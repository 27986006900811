.profiles {
  row-gap: 2rem;
}

.profile {
  max-width: 25%;
  flex: 25%;
}

.icon svg {
  width: 50px;
  height: 50px;
  color: var(--accent-color);
  margin-bottom: 10px;
}

.profile {
  text-align: center;
}

.title {
  font-size: 1.3rem;
  color: var(--accent-color);
  font-weight: 400;
  margin-bottom: 10px;
}

.message {
  color: rgb(0 0 0 / 50%);
}
@media (max-width: 768px) {
  .profile {
    flex: 50%;
    max-width: 50%;
  }
  .title {
    font-size: 1rem;
    font-size: clamp(0.9rem, 1.3vw, 1.2rem);
  }
  .message {
    font-size: 0.9rem;
    font-size: clamp(0.7rem, 1vw, 1rem);
  }
}
/* @media (max-width: 395px) {
  .profile {
    flex: 100%;
    max-width: 100%;
  }
} */
