.form {
  padding: 0 2rem;
}
.form_group {
  margin-bottom: 1rem;
}
.form_group > div {
  position: relative;
  display: flex;
  flex-direction: column;
}
.label {
  display: block;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  position: absolute;
  top: 1rem;
  color: rgb(0 0 0 / 20%);
  transition: all 0.3s ease;
  z-index: -1;
}
.form_group label span {
  color: var(--accent-color);
}
.form_group input,
.form_group textarea {
  padding: 15px 0;
  width: 100%;
  border-radius: 0;
  border: none;
  background: transparent;
  border-bottom: 2px solid rgb(0 0 0 / 20%);
  resize: vertical;
}
.form_group .underline::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: rgb(0 0 0 / 20%);
  transition: 0.3s ease;
}
.form_group .underline.error::before {
  width: 100%;
  background-color: red;
}
.form_group input:focus-within,
.form_group textarea:focus-within {
  outline: none;
}
.form_group input:focus-within + .label,
.form_group textarea:focus-within + .label,
.input_filled + .label {
  top: -5px;
  color: var(--text-color);
  font-size: 0.8rem;
  z-index: 2;
}
.form_group input:focus-within ~ .underline::before,
.form_group textarea:focus-within ~ .underline::before {
  width: 100%;
}
.form_group input::placeholder,
.form_group textarea::placeholder {
  font-size: 1rem;
  color: rgb(0 0 0 / 20%);
  line-height: 1;
}
.error_message {
  color: red;
  margin-top: 5px;
  font-size: 0.8rem;
}

.form_group input:autofill,
.form_group textarea:autofill {
  /* -webkit-box-shadow: 0 0 0 1000px var(--bg) inset !important; */
  transition: background-color 600000s 0s, color 600000s 0s;
}

.form_group input:-webkit-autofill,
.form_group textarea:-webkit-autofill {
  transition: background-color 600000s 0s, color 600000s 0s;
}
.form_group button {
  padding: 0.8rem 2rem;
  font-size: 1rem;
  text-transform: uppercase;
  border: none;
  background-color: rgb(0 0 0 / 80%);
  border-radius: 5px;
  color: #fff;
  line-height: 1;
  letter-spacing: 1px;
  cursor: pointer;
  transition: 0.3s ease;
}
.form_group button:hover {
  background-color: rgb(0 0 0 / 99%);
}
.form_group button:disabled {
  background-color: rgb(0 0 0 / 40%);
  cursor: no-drop;
}
.form_group button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
.section {
  position: relative;
}
.toast {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 1rem;
  padding: 1rem 1rem;
  background: var(--accent-4);
  max-width: calc(225px + 8rem);
  border-radius: 10px;
  color: #fff;
  width: 100%;
  overflow: hidden;
  animation: fadeIn 0.3s ease-in 2.5s forwards;
}
.toast.error {
  background: var(--accent-color);
}
.toast p {
  font-size: clamp(0.8rem, 1.2vw, 1rem);
  margin: 0;
}
.toast span {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5px;
  background: rgb(0 0 0 /10%);
  animation: progress 2.5s;
}

@keyframes progress {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
