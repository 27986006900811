.section {
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.heading {
  font-size: 20rem;
  font-size: clamp(5rem, 20vw, 20rem);
  font-weight: 900;
  line-height: 1;
  margin-bottom: 2rem;
}
.heading span {
  display: block;
  font-size: 3rem;
  font-size: clamp(2rem, 3vw, 3rem);
}
.section a {
  color: var(--accent-color);
}
