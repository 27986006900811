.info {
  margin-bottom: 1rem;
  display: flex;
  gap: 10px;
  align-items: center;
  font-style: normal;
}
.info svg {
  width: 30px;
  height: 30px;
  color: var(--accent-color);
}
