.timeline {
  margin: 2rem 0 0;
  position: relative;
}
.timeline > div {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 240px;
  gap: 1rem;
  position: relative;
}
.timeline > div:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background: #010101;
}
.info {
  display: inline-block;
  padding: 0 1rem;
}
.info h4 {
  color: var(--accent-color);
}
.card {
  position: relative;
  flex: 1;
}

.content {
  padding-right: 2rem;
}
.card:nth-child(even) {
  align-self: flex-end;
}

.timeline > div:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background: #010101;
}

.card:before {
  position: absolute;
  content: "";
  left: 0;
  top: 5px;
  height: 118px;
  width: 2px;
  background: linear-gradient(to top, transparent 50%, #4949493d 50%);
  background-size: 52px 12px;
}

.card:nth-child(even):before {
  top: auto;
  bottom: 0;
}

.card:after {
  position: absolute;
  content: "";
  left: -4px;
  top: 117px;
  height: 10px;
  width: 10px;
  background: #010101;
  border-radius: 10px;
}

.card:nth-child(even):after {
  top: auto;
  bottom: 115px;
}
@media (max-width: 768px) {
  /*
  Solution 1
  .timeline > div {
    flex-direction: column;
    height: auto;
  }
  .card:nth-child(even) {
    align-self: flex-start;
  }
  .timeline > div:before {
    width: 2px;
    height: 100%;
    top: 0;
  }
  .card:before {
    content: none;
  }
  .card:after,
  .card:nth-child(even):after {
    top: 5px;
  }
  .card {
    margin-bottom: 1rem;
  } */
  /* Solution 2 */

  .timeline {
    max-width: 500px;
    margin: 2rem auto 0;
  }
  .timeline > div {
    flex-direction: column;
    height: 100%;
  }
  .timeline > div:before {
    top: 0;
    left: calc(50% - 1px);
    width: 2px;
    height: 100%;
  }
  .card {
    width: 200px;
    border: 2px solid;
    padding: 10px;
  }
  .card:before {
    right: -52px;
    top: 5px;
    height: 2px;
    width: 58px;
    background: linear-gradient(
      to right,
      transparent 50%,
      rgb(73 73 73 / 24%) 50%
    );
    background-size: 19px 2px;
    left: auto;
  }
  .card:nth-child(even):before {
    top: 5px;
    bottom: auto;
    right: auto;
    left: -57px;
  }
  .card:after {
    left: auto;
    right: -57px;
    top: 1px;
  }
  .card:nth-child(even):after {
    top: 1px;
    bottom: auto;
    right: auto;
    left: -56px;
  }
}
@media (max-width: 530px) {
  .timeline {
    margin: 2rem 20px 0;
  }
  .card:nth-child(even) {
    align-self: flex-start;
  }
  .timeline > div:before {
    left: 0;
  }
  .card {
    padding: 0;
    margin-left: 20px;
    border: none;
  }
  .card:after,
  .card:nth-child(even):after {
    left: -24px;
    right: auto;
    top: 5px;
  }
  .card::before,
  .card:nth-child(even):before {
    top: 9px;
    left: -24px;
    right: auto;
    width: 30px;
    background-size: 10px 1px;
  }
}
