@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,700&display=swap");
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  /* transition: 0.1s ease; */
}
p {
  font-size: clamp(0.9rem, 1.5vw, 1rem);
  line-height: 1.5;
}
:root {
  --accent-color: #d80e18;
  --accent-2: #f39802;
  --accent-3: #fbd000;
  --accent-4: #8fc31e;
  --accent-5: #2d97e0;
  --accent-6: #a50382;
  --bg: #fbfbfb;
  --text-color: #231815;
  --bg-1: #f3f3f3;
}
html {
  scroll-behavior: smooth;
}
html,
body {
  overflow-x: hidden;
  max-width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}
body {
  max-width: 100%;
  background-color: var(--bg);
  position: relative;
  color: var(--text-color);
  font-family: "Raleway", sans-serif;
  font-weight: 300;
}
a {
  text-decoration: none;
  color: inherit;
}
p:not(:last-child) {
  margin-bottom: 1rem;
}
img {
  max-width: 100%;
}
.section {
  padding: 50px 0;
}
.section-100 {
  padding: 100px 0;
}
.section-box {
  padding: 100px 0;
  margin: 1rem;
  max-width: calc(100% - 2rem);
  border-radius: 1rem;
  background-color: var(--bg-1);
  overflow: hidden;
}
.bg-light {
  background-color: var(--bg-1);
}
.loader {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999999999;
  background-color: var(--bg);
  position: fixed;
  left: 0;
  top: 0;
  animation: fadeOut 0.5s ease 1.5s forwards;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.loader object {
  max-width: 80%;
  width: 300px;
}
.container {
  max-width: 1380px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}
.row {
  display: flex;
  flex-wrap: wrap;
}
.align-items-center {
  align-items: center;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-space-between {
  justify-content: space-between;
}
.justify-content-end {
  justify-content: flex-end;
}
.row-gap-1 {
  row-gap: 1rem;
}
.col-md-12 {
  max-width: 100%;
  flex: 100%;
}

.col-md-6 {
  max-width: 50%;
  flex: 0 0 50%;
}

.col-md-4 {
  max-width: 33.33%;
  flex: 0 0 33.33%;
}
.col-md-3 {
  max-width: 25%;
  flex: 25%;
}
.col-md-8 {
  max-width: calc(100% - 33.33%);
  flex: calc(100% - 33.33%);
}
.col-md-9 {
  max-width: 75%;
  flex: 75%;
}
.col-md-10 {
  max-width: 83.33%;
  flex: 83.33%;
}
.col-md-5 {
  max-width: 41.67%;
  flex: 41.67%;
}
.col-md {
  max-width: 100%;
  flex: 1;
}
.col-md-auto {
  flex: auto;
  width: auto;
  max-width: max-content;
}
.text-center {
  text-align: center;
}
.pre-heading {
  font-size: clamp(1rem, 1.4vw, 1.4rem);
  font-weight: 200;
  color: rgb(0 0 0 / 40%);
}

.heading {
  font-size: clamp(2rem, 2.5vw, 2.5rem);
  font-weight: 500;
  position: relative;
  display: inline-block;
}

/* .heading:before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  height: 3px;
  width: 40%;
  margin: 0 30%;
  background: var(--accent-color);
}
.heading.u-left:before {
  margin: 0;
}
.heading.no-u::before {
  content: none;
} */
.para {
  font-size: clamp(0.9rem, 1.2vw, 1.1rem);
  font-weight: 300;
}
.text-right {
  text-align: right;
}
.mb-2 {
  margin-bottom: 2rem !important;
}
.mb-4 {
  margin-bottom: 4rem !important;
}
.mb-1 {
  margin-bottom: 1rem !important;
}
.c-white .pre-heading {
  color: rgb(255 255 255 / 50%);
}
.c-white .heading::before {
  background-color: #fff;
}
.c-white button,
.c-white .heading {
  color: #fff;
}
.c-white button:before {
  border-color: rgb(255 255 255 / 50%);
}
.pb-0 {
  padding-bottom: 0;
}
.row-g-2 {
  row-gap: 2rem;
}
.card-heading {
  margin-bottom: 1rem;
  font-size: clamp(0.8rem, 2vw, 1.5rem);
  font-weight: 500;
}
@media (max-width: 768px) {
  .m-mb-2 {
    margin-bottom: 2rem;
  }
  .col-md-6,
  .col-md-4,
  .col-md-9,
  .col-md-8,
  .col-md-5,
  .col-md-3 {
    max-width: 100%;
    flex: 100%;
  }
  .section {
    padding: 50px 0;
  }
}
::selection {
  color: var(--bg);
  background: var(--accent-color);
}
