.section {
  background: linear-gradient(90deg, #00d2ff 0%, #3a47d5 100%);
  color: var(--bg);
  padding: 50px 0;
  display: flex;
  align-items: center;
  position: relative;
}
.section > div {
  width: 100%;
}
.section * {
  z-index: 2;
}
.section h1 {
  font-size: clamp(1.5rem, 3vw, 3.5rem);
  font-weight: 800;
  text-transform: uppercase;
}
.section h3 {
  font-size: clamp(1.5rem, 3vw, 3.5rem);
  font-weight: 500;
  margin-bottom: 1rem;
}

.section p {
  margin-bottom: 2rem;
  font-size: clamp(0.9rem, 1vw, 1.3rem);
}
.sub_heading {
  font-weight: 500;
  font-size: clamp(1rem, 2vw, 1.2rem);
  margin-bottom: 1rem;
  border-bottom: 1px solid;
  display: inline-block;
}
.ul {
  padding-left: 1.5rem;
  list-style-type: circle;
}
.ul li {
  margin-bottom: 1rem;
}
.sub_heading ~ p,
.sub_heading ~ ul {
  margin-left: 1.5rem;
}
@media (max-width: 768px) {
  .sub_heading ~ p,
  .sub_heading ~ ul {
    margin-left: 0;
  }
  .section {
    min-height: auto;
  }
  .section img {
    padding: 0 2rem;
  }
}
.contact a {
  color: var(--accent-color);
  font-weight: 600;
}

