.hero {
  /* min-height: 85vh;
  display: flex;
  align-items: center; */
  padding: 50px 0;
  position: relative;
  background: linear-gradient(90deg, #aaa0f9, #fde1ea);
}
.hero h1 {
  font-size: clamp(1.5rem, 3vw, 3.5rem);
  font-weight: 800;
  color: #fff;
  text-transform: uppercase;
}
.hero h3 {
  font-size: clamp(1.5rem, 3vw, 3.5rem);
  font-weight: 500;
  color: #fff;
  margin-bottom: 1rem;
}

.hero p {
  margin-bottom: 2rem;
  color: #fff;
  font-size: clamp(0.9rem, 1vw, 1.3rem);
}
@media (max-width: 768px) {
  .hero {
    min-height: auto;
  }
  .hero img {
    padding: 0 2rem;
  }
}
