.header {
  padding: 1rem 0;
}
.header.transparent {
  position: absolute;
  width: 100%;
  color: #fff;
  z-index: 99999;
}
.header.sticky_s {
  position: fixed;
  top: 0;
  color: var(--text-color);
  width: 100%;
  background: #fff;
  z-index: 99999;
  box-shadow: 0 20px 10px rgb(0 0 0 / 5%);
  animation: transformHeader 0.3s ease;
}
.header_top {
  animation: transformHeader_reverse 0.3s ease;
}
@keyframes transformHeader_reverse {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-200px);
  }
}
@keyframes transformHeader {
  0% {
    transform: translateY(-200px);
  }
  100% {
    transform: translateY(0);
  }
}
.logo {
  max-width: 120px;
}
.nav {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.nav nav ul {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 2rem;
}
.nav nav li {
  font-size: 1rem;
}
.contact_link {
  padding: 10px 20px;
  background-color: var(--accent-color);
  color: #fff;
  border-radius: 50px;
  line-height: 1;
}
.language_switch {
  width: 50px;
  text-align: center;
  padding: 10px 0;
  background: var(--text-color);
  line-height: 1;
  font-size: 0.9rem;
  border-radius: 10px;
  text-transform: uppercase;
  z-index: 9;
  position: relative;
  color: #fff;
  cursor: pointer;
}
.language {
  position: absolute;
  cursor: pointer;
  top: 0;
  background: var(--text-color);
  width: 100%;
  border-radius: 10px;
  clip-path: inset(0 0 100% 0);
  transition: 0.3s ease;
}
.language.active {
  clip-path: inset(0 0 0 0);
}
.language > * {
  padding: 10px 0;
}
.locale {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.locale img {
  border-radius: 2px;
}
@media (max-width: 768px) {
  .nav nav {
    display: none;
  }
}
