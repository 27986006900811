.contact {
  background-color: var(--text-color);
}
.btn {
  background-color: var(--accent-color);
  font-size: 1.3rem;
  border: none;
  border-radius: 50px;
  padding: 1rem 3rem;
  transition: 0.3s ease;
  cursor: pointer;
}
.btn:hover {
  background-color: #fff;
  color: var(--text-color);
}
