.button {
  background: transparent;
  border: none;
  outline: none;
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 1rem;
  position: relative;
  font-size: 1.2rem;
  cursor: pointer;
  z-index: 2;
  color: var(--text-color);
  line-height: 1.2;
}

.button:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  align-items: center;
  min-width: 50px;
  width: 50px;
  height: 50px;
  z-index: -1;
  border-radius: 50px;
  border: 1px solid rgb(0 0 0 / 50%);
  transition: 0.3s ease-in-out;
}

.button:hover:before {
  width: 100%;
}
