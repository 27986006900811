.features {
  border: 1px solid rgb(0 0 0 / 10%);
  padding: 4rem 2rem;
  min-height: 400px;
  border-radius: 1rem;
  margin: 0 0.5rem;
  height: calc(100% - 0.5rem);
  position: relative;
  transition: 0.3s ease;
}
.icon > div {
  margin-bottom: 1rem;
}
.features h3 {
  font-size: clamp(1.5rem, 2vw, 2rem);
  font-weight: 400;
  margin-bottom: 0.5rem;
}
.features p {
  font-size: clamp(0.9rem, 1vw, 1rem);
  line-height: 1.4;
  font-weight: 300;
}

/* .features:hover {
  transform: scale(1.01);
  box-shadow: 0 0 20px var(--accent-color); 
  border-color: transparent;
}

.feature_0:hover {
  box-shadow: 0 5px 20px #ffe4e5;
}
.feature_1:hover {
  box-shadow: 0 5px 20px #ffeed1;
}
.feature_2:hover {
  box-shadow: 0 5px 20px #f7efc8;
} */
@media (max-width: 768px) {
  .features {
    padding: 2rem 1rem;
    min-height: auto;
  }
}
